import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";

import './infopageheader.scss'

function App(props) {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
  }, []);

  return (
    <div className="relative">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        className="h-[50vh] sm:h-[60vh]"
        options={{
          fullScreen: {
            enable: false,
            zIndex: 0,
          },
          background: {
            color: {
              value: "#ffffff",
            },
          },
          fpsLimit: 120,
          "particles": {
            "number": {
              "value": 60,
              "density": {
                "enable": true,
                "value_area": 400
              }
            },
            "color": {
              "value": "#000000"
            },
            "shape": {
              "type": "circle",
              "stroke": {
                "width": 0,
                "color": "#000000"
              },
              "polygon": {
                "nb_sides": 5
              },
            },
            "opacity": {
              "value": 0.5,
              "random": false,
              "anim": {
                "enable": false,
                "speed": 0.1,
                "opacity_min": 0.1,
                "sync": false
              }
            },
            "size": {
              "value": 3,
              "random": true,
              "anim": {
                "enable": false,
                "speed": 10,
                "size_min": 0.1,
                "sync": false
              }
            },
            "line_linked": {
              "enable": true,
              "distance": 150,
              "color": "#262626",
              "opacity": 0.4,
              "width": 1
            },
            "move": {
              "enable": true,
              "speed": 2,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "bounce": false,
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
          },
          "interactivity": {
            "detect_on": "canvas",
            "events": {
              "onhover": {
                "enable": true,
                "mode": "grab"
              },
              "onclick": {
                "enable": true,
                "mode": "push"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 140,
                "line_linked": {
                  "opacity": 1
                }
              },
              "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
              },
              "repulse": {
                "distance": 200,
                "duration": 0.4
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
          "retina_detect": true
        }}
      />
      <div className="absolute inset-0 left-0 flex justify-center sm:justify-start items-center z-10 pointer-events-none">

        <div className="bg-white/75 h-fit w-80 sm:w-[30vw] text-center sm:text-left sm:ml-36 ">

          <p className="info-title text-neutral-800 font-mono font-bold p-4 underline underline-offset-[1rem]">
            {props.title}
          </p>

          <div className="px-4 pb-4">
            {props.description}
          </div>

        </div>

      </div>
    </div>

  );
};

export default App