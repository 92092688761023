import { useAuthenticator } from '@aws-amplify/ui-react';

export default function App() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <>

      <b>ADMIN PAGE!</b>
      <h1>Welcome {user.username}</h1>
      <button onClick={signOut}>Sign out</button>

    </>
  );
}