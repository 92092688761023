import React, { Component } from "react";

import { ScrollableDiv, ProjectCard } from '../../components/'

// Images
import drschusterImage from './images/drSchuster.png'
import miningImage from './images/Mining.png'
import pewnyWebsiteImage from './images/pewnyWebsiteScreenshot.png'
import accountingAutomationImage from './images/accountingAutomation.png'
import webConsultingImage from './images/webConsulting.png'
import hydropowerImage from './images/hydroPower.png'
import rechnungImage from './images/rechnungSchreiben.png'


class Projects extends Component {
    projects = [
        {
            title: "rechnung-schreiben.com",
            description: "A simple, free to use tool to create invoices. Implemented in Typescript and react, hosted on AWS.",
            image: rechnungImage,
            href: "https://www.rechnung-schreiben.com"
        },
        {
            title: "Hydropower Plant Automation & Monitoring",
            description: "Automated maintenance schedules and remote access for 24 Hour monitoring.",
            image: hydropowerImage,
            href: "#!"
        },
        {
            title: "Accounting Automation Software",
            description: "Thousands of entries had to be manually processed every month. This is now fully automated and saves the customer a lot of time.",
            image: accountingAutomationImage,
            href: "#!"
        },
        {
            title: "Web Analysis & DSGVO Consulting",
            description: "SEO and design improvements, correction of Privacy Policy to be in order with the DSGVO.",
            image: webConsultingImage,
            href: "#!"
        },
        {
            title: "claudia-pewny.at",
            description: "Website with a simple, modern design for an accounting company. Made with React.js and hosted serverless AWS.",
            image: pewnyWebsiteImage,
            href: "#!"
        },
        // {
        //     title: "Crypto Mining Farm Setup, Consulting & Monitoring",
        //     description: "Crypto mining rig setup, consulting, network installation. A custom monitoring solution with automated maintenance cycles.",
        //     image: miningImage,
        //     href: "#!"
        // },
        {
            title: "drschuster.at",
            description: "Website re-design for an pulmonologist.",
            image: drschusterImage,
            href: "#!"
        },
    ]

    render() {
        return (
            <>
                <div className="mx-8 sm:mx-36 sm:py-8">
                    <div className="sm:pb-8">
                        <h1 className="font-mono text-black pb-4 text-4xl sm:text-[8vmin]">
                            Projects
                        </h1>
                    </div>
                </div>

                <div className="sm:mx-32 overflow-auto">
                    <ScrollableDiv
                        items={this.projects.map((project) => (
                            <div className="pt-4 pb-8 px-4"
                                key={project.title}
                            >
                                <ProjectCard
                                    title={project.title}
                                    description={project.description}
                                    image={project.image}
                                    href={project.href}
                                />
                            </div>
                        ))}
                    />
                </div>
            </>
        )
    }
}

export default Projects