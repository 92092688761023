import React, { Component } from "react";

class ServicesLarge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeService: this.props.serviceGroups[0],
        };
        this.changeService = this.changeService.bind(this);
    }

    changeService(e) {
        this.setState({
            activeService: this.props.serviceGroups[Number(e.currentTarget.dataset.id)],
        })
    }

    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    getStyleMenu(active, light) {
        var base = " px-3 py-4 text-2xl font-medium cursor-pointer font-bold";
        if (active) {
            if (light) {
                return "text-black border-l-4 border-black" + base
            } else {
                return "text-white border-l-4 border-white" + base
            }
        } else {
            if (light) {
                return "text-gray-600 border-l-2 hover:text-black" + base
            } else {
                return "text-gray-400 border-l-2 hover:text-white" + base
            }
        }
    }

    render() {
        return (
            <>
                <div className="flex gap-32 columns-2 h-full pt-12">
                    <div className="">
                        <ul className="w-[14rem]">
                            {this.props.serviceGroups.map((item, index) => (
                                <li
                                    onClick={this.changeService}
                                    key={item.name}
                                    className={this.getStyleMenu(this.state.activeService === item, this.props.theme === "light")}
                                    data-id={index}
                                >
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="p-4">
                        <div className={this.classNames(this.props.theme === "light" ? "text-black" : "text-white", "text-4xl font-medium font-bold")}>
                            {this.state.activeService.name}
                        </div>

                        <div className="grid grid-cols-2 pt-8 display: inline-block w-[64rem]">
                            {this.state.activeService.services.map((service) => (
                                <div
                                    className="pb-4"
                                    key={service.name}
                                >
                                    <div className={this.classNames(
                                        this.props.theme === "light" ?
                                            "border-gray-400 text-gray-400 hover:border-black hover:text-black" :
                                            "border-gray-400 text-gray-400 hover:border-white hover:text-white",
                                        "border-l-2 w-[32rem] px-4 h-32 hover:border-l-4 cursor-pointer grayscale hover:grayscale-0"
                                    )}>
                                        <div className="text-2xl font-bold">
                                            {service.name}
                                        </div>
                                        <img src={service.image} className='float-right py-4 mr-8' style={{width: '50px'}} alt={service.image ? `logo of ${service.name}` : ""}></img>
                                        <div className="text-md font-medium pt-2 max-w-[24rem]">
                                            {service.description}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ServicesLarge