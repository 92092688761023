import { LandingPageHeader } from "../../components"
import { Services, Technologies, Projects } from "../../containers"

export default function Landing() {
  return (
    <>


      <div className="pt-8">
        <LandingPageHeader />
      </div>

      <div className='bg-neutral-800 h-[20vh]' />

      <Services />

      <Technologies />


      <div className='bg-whtie py-8'>
        <Projects />
      </div>


    </>
  );
}