import { InfoPageHeader } from "../../components"

export default function Landing() {
    return (
        <>

            <InfoPageHeader
                title="Technologies"
                description="Page under development..."
            />

            <div className='bg-neutral-800 h-[20vh]' />

            {/* 
                - Front-end
                - Back-end
                - Cloud
                - Mobile
                - Contact
            */}

        </>
    );
}