import { useEffect } from "react";

import { Authenticator, useAuthenticator, View, Image, useTheme, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            width={300}
            alt="SUS logo"
            src={process.env.PUBLIC_URL + 'susLogo.png'}
          />
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            S&S Technologies GmbH
          </Heading>
        );
      },
    }
  }


  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
      <Authenticator
        components={components}
        hideSignUp={true}
      ></Authenticator>
    </View>
  );
}

export default Login