import { Component } from "react";

class Web extends Component {

    render() {
        return (
            <>
                <div>Web</div>
            </>
        )
    }
}

export default Web