import { InfoPageHeader } from "../../components"
import { Web, Backend, Automation, Cloud, Mobile } from "../../components/Services"
import { Tabs, TabList, TabPanels, Tab, TabPanel, css } from '@chakra-ui/react'
import { Component } from "react"

class Services extends Component {

    tabs = [
        { name: 'Web Development', content: <Web /> },
        { name: 'Backend & APIs', content: <Backend /> },
        { name: 'Automation', content: <Automation /> },
        { name: 'Cloud', content: <Cloud /> },
        { name: 'Mobile', content: <Mobile /> },
    ]

    render() {
        return (
            <>

                <InfoPageHeader
                    title="Services"
                    description="Page under development..."
                />

                <div className='bg-neutral-800 h-[20vh] mb-12' />

                <div className="bg-white h-96 sm:mx-36">
                    <Tabs size='lg'>

                        <TabList
                            overflowX="auto"
                            overflowY="hidden"
                            css={css({
                                scrollbarWidth: 'none',
                                '::-webkit-scrollbar': { display: 'none' },
                                '-webkit-overflow-scrolling': 'touch',
                                boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                border: '0 none',
                            })}
                        >
                            {this.tabs.map((item) => (
                                <Tab
                                    _selected={{ color: '#262626', fontWeight: '500' }}
                                    key={item.name}
                                >
                                    {item.name}
                                </Tab>
                            ))}
                        </TabList>

                        <TabPanels>
                            {this.tabs.map((item) => (
                                <TabPanel
                                    key={item.name}
                                >
                                    {item.content}
                                </TabPanel>
                            ))}
                        </TabPanels>

                    </Tabs>
                </div>

                {/* <div className='bg-neutral-800 h-[20vh] mb-12 text-white'>
                    Development Cycle
                </div>
                <div className='bg-white h-[20vh] mb-12'>
                    Consulting
                </div>
                <div className='bg-neutral-800 h-[20vh] mb-12 text-white'>
                    Pricing
                </div>
                <div className='bg-white h-[20vh] mb-12'>
                    Contact
                </div> */}

            </>
        )
    };
}

export default Services