import { Component } from "react";

class Cloud extends Component {

    render() {
        return (
            <>
                <div>Cloud</div>
            </>
        )
    }
}

export default Cloud