import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

// @ts-ignore
import useDrag from "./useDrag.ts";

import "./hideScrollbar.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function ScrollableDiv(props) {

  const [mouseDown, setMouseDown] = React.useState(false);

  // NOTE: for drag by mouse
  const { dragStart, dragStop, dragMove } = useDrag();

  const handleMouseDown = (ev) => {
    setMouseDown(true);
    dragStart(ev);
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    dragStop();
  };

  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  return (
    <>
      <div>
        <div
          onMouseLeave={dragStop}
          style={{ cursor: mouseDown ? "grabbing" : "grab" }}>
          <ScrollMenu
            onMouseDown={() => handleMouseDown}
            onMouseUp={() => handleMouseUp}
            onMouseMove={handleDrag}
          >
            {props.items}
          </ScrollMenu>
        </div>
      </div>
    </>
  );
}
export default ScrollableDiv;

// function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
//   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

//   if (isThouchpad) {
//     ev.stopPropagation();
//     return;
//   }

//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else if (ev.deltaY > 0) {
//     apiObj.scrollPrev();
//   }
// }