import { Component } from "react";

class Backend extends Component {

    render() {
        return (
            <>
                <div>Backend</div>
            </>
        )
    }
}

export default Backend