import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

class ServicesSmall extends Component {

    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    render() {
        return (
            <>
                <Accordion allowToggle className="font-mono">
                    {this.props.serviceGroups.map((item) => (
                        <AccordionItem
                            key={item.name}
                        >
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton>
                                            <Box className={this.classNames(
                                                isExpanded ?
                                                this.props.theme === "light" ? "text-black font-bold underline" : "text-white font-bold underline"  : 
                                                this.props.theme === "light" ? "text-gray-600" : "text-gray-400",
                                                "text-2xl font-medium cursor-pointer"
                                                )}>
                                                {item.name}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <ul className={this.classNames(this.props.theme === "light" ? "text-black" : "text-white", "text-md font-medium cursor-pointer font-bold")}>
                                            {item.services.map((service) => (
                                                <li
                                                    key={service.name}
                                                >- {service.name} </li>
                                            ))}
                                        </ul>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    ))}
                </Accordion>
            </>
        )
    }
}

export default ServicesSmall