import React, { Component } from "react";

import { ContentInfo } from '../../components/'

// Front-end Images
import angularImage from './images/angular.png'
import cssImage from './images/css.png'
import htmlImage from './images/html.png'
import javascriptImage from './images/javascript.png'
import reactImage from './images/react.png'

// Back-end Images
import pythonImage from './images/python.png'
import nodejsImage from './images/nodejs.png'
import dotnetImage from './images/dotnet.png'
import javaImage from './images/java.png'

// Cloud Images
import awsImage from './images/aws.png'

class Technologies extends Component {

    serviceGroups = [
        {
            name: 'Front-end',
            services: [
                {
                    name: "React",
                    description: "React JS is a powerful frontend framewort to develop responsive websites like this one!",
                    image: reactImage,
                },
                {
                    name: "Angular",
                    description: "Angular is mostly used to build complex enterprise-grade apps like single-page apps and progressive web apps.",
                    image: angularImage,
                },
                {
                    name: "Java Script / Typescript",
                    description: "Javascript is used to create interactive web pages. Typescript is a superset of JavaScript.",
                    image: javascriptImage,
                },
                {
                    name: "HTML",
                    description: "HTML - Hypertext Markup Language - creates the underlying structure of websites.",
                    image: htmlImage,
                },
                {
                    name: "CSS/SCSS",
                    description: "CSS styles the otherwise plain and boring looking webpage and makes it more user friendly.",
                    image: cssImage,
                },
            ],
        },
        {
            name: 'Back-end',
            services: [
                {
                    name: "Python",
                    description: "Python is a general-purpose language, meaning it can be used to create a variety of different programs and isn't specialized for any specific problems.",
                    image: pythonImage,
                },
                {
                    name: "Node.js",
                    description: "Node Js is an asynchronous event-driven JavaScript runtime it is designed to build scalable network applications.",
                    image: nodejsImage,
                },
                {
                    name: ".NET",
                    description: ".NET is a cross-platform, open source developer platform built on a high-performance runtime that is used in production by many high-scale apps.",
                    image: dotnetImage,
                },
                {
                    name: "Java",
                    description: "Java is a widely used object-oriented programming language and software platform that runs on billions of devices of all kinds.",
                    image: javaImage,
                },
            ],
        },
        {
            name: 'Cloud',
            services: [
                {
                    name: "AWS",
                    description: "Amazon Web Services is a comprehensive cloud computing platform that includes a mixture IaaS, PaaS as well as SaaS offerings.",
                    image: awsImage,
                },
            ],
        },
    ]

    render() {

        return (
            <div className="" >
                <ContentInfo
                    serviceGroups={this.serviceGroups}
                    title="Technologies"
                    theme="dark"
                />
            </div>
        )
    }
}

export default Technologies