import React, { Component } from "react";
import './pageheader.css'

class PageHeader extends Component {

    // constructor(props) {
    //     super(props);
    // }

    options = {
        max: 8,
        perspective: 5000,
        scale: 1,
    }

    render() {
        return (
            <>
            <div className="bg-cover pb-12 bg-[length:80vw] bg-no-repeat bg-right-bottom"
                style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/mountain.png')`
                }}
            >

                <div className="grid place-content-center sm:place-content-start text-center sm:text-left sm:mx-36">
                    <div className="header-text-1 font-mono">
                        Custom Software
                    </div>
                    <div className="header-text-2 font-mono">
                        Automation
                    </div>
                    <div className="header-text-3 font-mono">
                        Web...
                    </div>
                </div>
            </div>

            </>
        )
    }
}

export default PageHeader