import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import { ChakraProvider } from '@chakra-ui/react'

import { Login, RequireAuth, Navbar, Footer, ScrollToTop } from './components';

import { Landing, About, Services, Technologies, Projects, Faq, Brands, Impressum } from './pages';
import AdminPage from './pages/admin';

import '@aws-amplify/ui-react/styles.css'

const App = () => {
  return (
    <ChakraProvider>
      <Authenticator.Provider>
        <BrowserRouter>

          <Navbar />

          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/technologies" element={<Technologies />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/brands" element={<Brands />} />
              <Route path="/imprint" element={<Impressum />} />

              <Route path="/login" element={<Login />} />
              <Route path="/admin" element={<RequireAuth> <AdminPage /> </RequireAuth>} />
            </Routes>
          </ScrollToTop>

          <Footer />

        </BrowserRouter>
      </Authenticator.Provider>
    </ChakraProvider>
  );
}

export default App