import React, { Component } from "react";

import { ContentInfo } from '../../components/'

class Services extends Component {
    serviceGroups = [
        {
            name: 'Team',
            services: [
                {
                    name: "Frontend Developers",
                    description: "Frontend Developers bring your applications to life! Responsive top-of-the-line frontend user interfaces are a must for every modern application.",
                },
                {
                    name: "Backend Developers",
                    description: "Server-side backends experts handle the logic of your applications ranging from simple contact forms to extensive enterprise web-apps.",
                },
                {
                    name: "UI/UX Designers",
                    description: "The user-interface is the bridge between humans and computers. A competent UI/UX design team is crucial to inspire user engagement and interaction.",
                },
                {
                    name: "DevOps Engineers",
                    description: "Introduces processes and tools to balance needs throughout the development cycle, from coding and deployment, to maintenance and updates.",
                },
                {
                    name: "Business Analysts",
                    description: "Business Analysts ensure smooth operation and project workflows, identifying optimal technical solutions and pointing out potential issues.",
                },
            ],
        },
        {
            name: 'Custom software',
            services: [
                {
                    name: "Web",
                    description: "Web development at any scale for your business. We offer a transparent web application development process, tailor-made for you.",
                },
                {
                    name: "APIs",
                    description: "Application Programming Interfaces are the link between different applications and can have a massive impact on application performance.",
                },
                {
                    name: "Automation",
                    description: "Automation of seemingly mundane tasks can be a huge time and money saver for you and your business.",
                },
                {
                    name: "Cloud",
                    description: "Cloud deployments and IaaS are getting more and more common as on premise servers are expensive in both acquisition and maintenance.",
                },
                {
                    name: "Mobile",
                    description: "Mobile apps have transformed the way we perform business. Today, mobile friendly user interfaces are essential for every business.",
                },
            ],
        },
        {
            name: 'Consulting',
            services: [
                {
                    name: "IT Consulting",
                    description: "Our IT experts can help you realize your projects and make steps in the right direction.",
                },
                {
                    name: "Blockchain and Crypto",
                    description: "We can provide long term experience in the crypto currency and blockchain technology field including mining, NFTs and smart contracts.",
                },
                {
                    name: "Prototyping",
                    description: "Before jumping into the full-scale development process a simple prototype of your idea can help to identify potential problems or improvements.",
                },
                {
                    name: "Business Consulting",
                    description: "Getting started in the IT or Ecommerce sector can be a daunting task. We help you to avoid mistakes that might not be so obvious as one might think.",
                },
                {
                    name: "Business Audit",
                    description: "Let us help you identify your businesses strengths and weaknesses, make a profitability analysis or launch a new product or service!",
                },
            ],
        },
    ]

    render() {

        return (
            <div className="">
                <ContentInfo
                    serviceGroups={this.serviceGroups}
                    title="Services"
                    theme="light"
                />
            </div>
        )
    }
}

export default Services