import { InfoPageHeader } from "../../components"
import { Component } from "react"
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

class FAQ extends Component {

    questions = [
        {
            question: "Question1",
            answer: "Answer Text..."
        },
        {
            question: "Question2",
            answer: "Answer Text..."
        },
        {
            question: "Question3",
            answer: "Answer Text..."
        },
        {
            question: "Question4",
            answer: "Answer Text..."
        },
    ]

    render() {
        return (
            <>
                <InfoPageHeader
                    title="FAQ"
                    description="Here you can find the answers to the most commonly asked questions."
                />

                <div className='bg-neutral-800 h-[20vh] mb-12' />

                <div className="mx-8 sm:mx-36 py-4">

                    <div className="text-4xl font-mono py-4">
                        Frequently Asked Questions
                    </div>

                    <Accordion allowToggle>
                        {this.questions.map((question) => (
                            <AccordionItem
                                key={question.question}
                            >
                                <h2>
                                    <AccordionButton>
                                        <Box flex='1' textAlign='left'>
                                            {question.question}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    {question.answer}
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>


            </>
        )
    };
}

export default FAQ