import { Component } from "react";

class Mobile extends Component {

    render() {
        return (
            <>
                <div>Mobile</div>
            </>
        )
    }
}

export default Mobile