import { InfoPageHeader } from "../../components"

export default function Landing() {
    return (
        <>

            <InfoPageHeader
                title="About"
                description="In development"
            />

            <div className='bg-neutral-800 h-[20vh]' />

        </>
    );
}