import React, { Component } from 'react'
import { InfoPageHeader } from "../../components"


export class Impressum extends Component {


    render() {
        return (
            <InfoPageHeader
                title="Imprint"
                description={
                    <>
                        <b>
                            S&S Technologies GmbH
                        </b>
                        <p>
                            IT-Service Provider <br />
                            UID-Nr: ATU77676212 <br />
                            FN: 571385y <br />
                            FB-Gericht: LG Salzburg
                        </p>
                        <b>
                            Address:
                        </b>
                        <p>
                            Haspingerstrasse 4 <br />
                            5550 Radstadt <br />
                            Salzburg, Austria <br />
                        </p>
                        <b> Contact </b>
                        <p>
                            office@ss-technologies.at <br />
                            +43 (0) 677 6121 1681
                        </p>
                    </>
                }
            />
        )
    }

}

export default Impressum
