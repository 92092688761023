import React, { Component } from "react";

class ProjectCard extends Component {

    render() {
        return (
            <>
                <div className="relative w-[20rem] h-[30rem] justify-center">
                    <div className="absolute rounded-lg shadow-lg bg-white h-[30rem]">

                        <div className="pointer-none select-none">
                            <img
                                className="rounded-t-lg object-cover  h-56 w-96"
                                src={this.props.image} alt=""
                                draggable="false"
                            />
                        </div>

                        <div className="p-6">
                            <div className="h-14">
                                <h5 className="text-gray-900 text-xl font-medium mb-2 select-none">{this.props.title}</h5>
                            </div>
                            <div className="h-28">
                                <p className="text-gray-700 text-base mb-4 h-20 select-none">
                                    {this.props.description}
                                </p>
                            </div>
                            <a
                                href={this.props.href} target="_blank"
                                type="button"
                                className="select-none inline-block px-6 py-2.5 bg-neutral-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-neutral-600 hover:shadow-lg">
                                More
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ProjectCard