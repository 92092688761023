import React, { Component } from "react";

import ContentSmall from './ContentViewSmallScreen'
import ContentLarge from './ContentViewLargeScreen'


class ContentInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        console.log(window.innerWidth);
        this.setState({ width: window.innerWidth });
    };

    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    render() {
        const { width } = this.state;
        const isMobile = width <= 1500;

        if (isMobile) {
            return (
                <div className={this.props.theme === "light" ? "py-8" : "py-8 bg-neutral-800"}>
                    <div className="mx-8">
                        <h1 className={this.classNames(
                            this.props.theme === "light" ? "text-black" : "text-white",
                            "font-mono pb-4 text-4xl"
                        )}
                        >
                            {this.props.title}
                        </h1>
                        <ContentSmall serviceGroups={this.props.serviceGroups} theme={this.props.theme} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className={this.props.theme === "light" ? "py-8" : "py-8 bg-neutral-800"}>
                    <div className="mx-36">
                        <h1 className={this.classNames(
                            this.props.theme === "light" ? "text-black" : "text-white",
                            "font-mono text-[8vmin]"
                        )}
                        >
                            {this.props.title}
                        </h1>
                        <ContentLarge serviceGroups={this.props.serviceGroups} theme={this.props.theme} />
                    </div>
                </div>
            )
        }

    }
}

export default ContentInfo