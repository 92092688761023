import { InfoPageHeader } from "../../components"
import { Component } from "react"

class Brands extends Component {

    render() {
        return (
            <>
                <InfoPageHeader
                    title="Brands"
                    description="Page under development..."
                />

                <div className='bg-neutral-800 h-[20vh] mb-12' />
            </>
        )
    };
}

export default Brands