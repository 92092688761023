/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Component } from 'react'
import { NavLink } from 'react-router-dom';

class Navbar extends Component {

    // constructor(props) {
    //     super(props);
    // }

    navigation = [
        { name: 'Home', href: '/' },
        // { name: 'About Us', href: '/about' },
        { name: 'Services', href: '/services' },
        { name: 'Technologies', href: '/technologies' },
        { name: 'Projects', href: '/projects' },
        // { name: 'Brands', href: '/brands' },
    ]

    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    render() {
        return (
            <Disclosure as="nav" className="sticky z-50 top-0 bg-white h-24 border-b-2">
                {({ open, isActive }) => (
                    <>
                        {console.log()}
                        <div className="max-w-[80vw] sm:max-w-[88vw] mx-auto px-2 sm:px-6 lg:px-8">
                            <div className="relative flex items-center justify-between h-24">
                                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex-1 flex items-stretch justify-end sm:items-stretch sm:justify-start">
                                    <div className="flex-shrink-0 flex items-center">
                                        <a href='/'>
                                            <img
                                                className="block lg:hidden h-12 w-auto"
                                                src={process.env.PUBLIC_URL + 'susLogo.png'}
                                                alt="logo"
                                            />
                                            <img
                                                className="hidden lg:block h-12 w-auto"
                                                src={process.env.PUBLIC_URL + 'susLogo.png'}
                                                alt="logo"
                                            />
                                        </a>
                                    </div>

                                    <div className="hidden sm:block sm:ml-6">
                                        <div className="flex space-x-4">
                                            <a
                                                href='/'
                                                className='text-black px-3 py-2 text-2xl font-bold cursor-pointer'
                                            >
                                                SUS-TECH
                                            </a>

                                        </div>
                                    </div>

                                </div>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                                    <div className="hidden sm:block sm:ml-6">
                                        <div className="flex space-x-4">
                                            {this.navigation.map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    className={(state) =>
                                                        this.classNames(
                                                            state.isActive ? 'text-black font-bold decoration-2 underline underline-offset-2' : 'hover:text-black',
                                                            'px-3 py-2 rounded-md text-lg font-medium'
                                                        )}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                {this.navigation.map((item) => (
                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className={(state) =>
                                            this.classNames(
                                                state.isActive ?
                                                    'block px-3 py-2 rounded-md text-base font-medium  bg-neutral-800 text-white' :
                                                    'block px-3 py-2 rounded-md text-base font-medium  bg-gray-100 text-gray-800 hover:bg-gray-700 hover:text-white'
                                            )}
                                    >
                                        <Disclosure.Button>
                                            {item.name}
                                        </Disclosure.Button>
                                    </NavLink>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        )
    }
}

export default Navbar
